import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle} location={ location }>
      <Seo title="Contact" keywords={[`devon rex`, `devon rex cats`, `cats`, `kittens`, `devon rex kittens`, `devon rex breeder`]} />

      <article className="page-template no-image">
        <h3>Contact</h3>
        <p>Please email at <a href="mailto:christispugs@hotmail.com">christispugs@hotmail.com</a> or for a quicker response, call me at <a href="tel:(208)(8808769)">208-880-eight seven six nine</a>.</p>
        <h3>What to Expect</h3>
        <p>Our kittens are raised in our home and we spend much time conditioning them to thrive in happy living environments. All of our kittens are familiar with our dogs and they love their canine friends. The Devon Rex likes to have companions when you are not home. Other cats, dogs, ferrets, or any little friend suits a Devon Rex.</p>
        <h4>1 Application</h4>
        <p>We will require an application/purchase agreement for all potential kitten owners.</p>
        <h4>2 Approval</h4>
        <p>After the approval is granted, we will require a non refundable deposit to hold your kitten until it is personally picked up or we deliver the baby to your home.</p>
        <h4>3 Selection</h4>
        <p>All kittens are first come first picked basis.</p>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Component = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ContactPage location={props.location} data={data} {...props} />
    )}
  />
)

export default Component;